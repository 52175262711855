<template>
  <div class="h-full">
    <div class="bottom_space rounded py-2 h-full bg-white">
      <div v-if="!isLoading">
        <div style="overflow-y: autos; white-space: nowrap;" class="p-3 cust_card">
          <div class="heading-3 text-text1 ">● Organization Detail:</div>
          <!-- <div class="heading-3 text-text1">● Basic Detail:</div> -->
          <div class="grid grid-cols-12 mt-4">
            <div class="box col-span-12 xl:col-span-3">
              <div class="">
                <div class="p-1">
                  <p class="mb-0" style="display: flex;">
                    <span class="text-text2 heading-5 font-bold"><p class="mb-0" style="width:173px">Organization Code</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 heading-5 whitespace-pre-line" v-if="compDetail.organizationCode !== ''">{{compDetail.organizationCode}}</span>
                    <span class="text-text1 heading-5" v-else>--</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0" style="display: flex;">
                    <span class="text-text2 heading-5 font-bold"><p class="mb-0" style="width:173px">Organization Name</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 heading-5 whitespace-pre-line" v-if="compDetail.organizationName !== ''">{{compDetail.organizationName}}</span>
                    <span class="text-text1 heading-5" v-else>--</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0" style="display: flex;">
                    <span class="text-text2 heading-5 font-bold"><p class="mb-0" style="width:173px">Organization Industry</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 heading-5 whitespace-pre-line" v-if="compDetail.industryTypeName !== ''">{{compDetail.industryTypeName}}</span>
                    <span class="text-text1 heading-5" v-else>--</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0" style="display: flex;">
                    <span class="text-text2 heading-5 font-bold"><p class="mb-0" style="width:173px">Organization Size</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 heading-5 whitespace-pre-line" v-if="compDetail.companySize !== ''">{{compDetail.companySize}}</span>
                    <span class="text-text1 heading-5" v-else>--</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0" style="display: flex;">
                    <span class="text-text2 heading-5 font-bold"><p class="mb-0" style="width:173px">Annual Revenue</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 heading-5 whitespace-pre-line" v-if="compDetail.estimateAnnualRevenue !== ''">{{compDetail.estimateAnnualRevenue}}</span>
                    <span class="text-text1 heading-5" v-else>--</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0" style="display: flex;">
                    <span class="text-text2 heading-5 font-bold"><p class="mb-0" style="width:173px">Referral source</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 heading-5 whitespace-pre-line" v-if="compDetail.sourceNote !== ''">
                      <span class="mb-0 whitespace-pre-line text-text1 heading-5">
                      {{compDetail.sourceNote === '' ? '-' : (compDetail.sourceNote.length > 50)? readMoreNote ?  compDetail.sourceNote : compDetail.sourceNote.substr(0, 50) : compDetail.sourceNote}}
                      <span v-if="compDetail.sourceNote.length > 50" class="text-primary cursor-pointer heading-7" @click="readMoreNote = !readMoreNote">
                        {{readMoreNote === false ? 'Read More...' : 'Read Less...'}}</span>
                    </span>
                    </span>
                    <span class="text-text1 heading-5" v-else>--</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0" style="display: flex;">
                    <span class="text-text2 heading-5 font-bold"><p class="mb-0" style="width:173px">Requested Ip</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 heading-5 whitespace-pre-line" v-if="compDetail.requestedIp !== ''">{{compDetail.requestedIp}}</span>
                    <span class="text-text1 heading-5" v-else>--</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0" style="display: flex;">
                    <span class="text-text2 heading-5 font-bold"><p class="mb-0" style="width:173px">Created date</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 heading-5 whitespace-pre-line" v-if="compDetail.createdOn !== ''">{{compDetail.createdOn | dateTimeWithName}}</span>
                    <span class="text-text1 heading-5" v-else>--</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0" style="display: flex;">
                    <span class="text-text2 heading-5 font-bold"><p class="mb-0" style="width:173px">isActive</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 heading-5" v-if="!compDetail.isActive">
                      <p class=" text-red-700 heading-5  border border-red-600 rounded-md px-3">Inactive</p>
                    </span>
                    <span class="text-text1 heading-4" v-else>
                      <p class=" text-green-700 heading-5  border border-green-600 rounded-md px-3">Active</p>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div class="box col-span-12 xl:col-span-9 xl:pl-8">
              <div class="grid grid-cols-1 mt-2 md:mt-2 xl:mt-0 2xl:mt-0 sm:grid-cols-2 md:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4 row-span-1">
                <div class="">
                  <div @click="smoothScroll('customers')" class="flex items-center h-28 pl-6 border-l-4 hover:border-primary border-primary3 hover:shadow-md cursor-pointer cust_card">
                    <div>
                      <div class="heading-1">{{competitionDetailObj.customers}}</div>
                      <div class="text-text1 heading-3">{{competitionDetailObj.customers > 0 ? 'Customers' : 'Customer'}}</div>
                    </div>
                  </div>
                </div>
                <div class="">
                  <div @click="smoothScroll('leads')" class="flex items-center h-28 hover:border-primary pl-6 border-l-4 border-primary3  hover:shadow-md cursor-pointer cust_card">
                    <div>
                      <div class="heading-1">{{competitionDetailObj.leads}}</div>
                      <div class="text-text1 heading-3">{{competitionDetailObj.leads > 0 ? 'Leads' : 'Lead'}}</div>
                    </div>
                  </div>
                </div>
                <div class="">
                  <div @click="smoothScroll('jobs')" class="flex items-center h-28 pl-6 border-l-4 hover:border-primary border-primary3  hover:shadow-md cursor-pointer cust_card ">
                    <div>
                      <div class="heading-1">{{competitionDetailObj.jobs}}</div>
                      <div class="text-text1 heading-3">{{competitionDetailObj.jobs > 0 ? 'Jobs' : 'Job'}}</div>
                    </div>
                  </div>
                </div>
                <div class="">
                  <div @click="smoothScroll('users')" class="flex items-center h-28 pl-6 border-l-4 hover:border-primary border-primary3  hover:shadow-md cursor-pointer cust_card ">
                    <div>
                      <div class="heading-1">{{competitionDetailObj.teamMembers}}</div>
                      <div class="text-text1 heading-3">Team {{competitionDetailObj.teamMembers > 0 ? 'Members' : 'Member'}}</div>
                    </div>
                  </div>
                </div>
                <div class="">
                  <div @click="smoothScroll('devices')" class="flex items-center h-28 pl-6 border-l-4 hover:border-primary border-primary3  hover:shadow-md cursor-pointer cust_card ">
                    <div>
                      <div class="heading-1">{{competitionDetailObj.deviceCount}}</div>
                      <div class="text-text1 heading-3">Device{{competitionDetailObj.deviceCount > 0 ? 's' : ''}}</div>
                    </div>
                  </div>
                </div>
                <div class="">
                  <div @click="smoothScroll('invoices')" class="flex items-center h-28 pl-6 border-l-4 hover:border-primary border-primary3  hover:shadow-md cursor-pointer cust_card ">
                    <div>
                      <div class="heading-1">{{competitionDetailObj.totalInvoiceCount}}</div>
                      <div class="text-text1 heading-3">{{competitionDetailObj.totalInvoiceCount > 0 ? 'Invoices' : 'Invoice'}}</div>
                    </div>
                  </div>
                </div>
                <div class="">
                  <div @click="smoothScroll('payments')" class="flex items-center h-28 hover:border-primary pl-6 border-l-4 border-primary3  hover:shadow-md cursor-pointer cust_card">
                    <div>
                      <div class="heading-1">{{competitionDetailObj.totalPaymentPaid | amountFormaterWithToFix}}</div>
                      <div class="text-text1 heading-3">Payment</div>
                    </div>
                  </div>
                </div>
                <div class="">
                  <div @click="goToAttchment()" class="flex items-center h-28 pl-6 border-l-4 border-primary3 hover:border-primary  hover:shadow-md cursor-pointer cust_card ">
                    <div>
                      <div class="heading-1" v-if="competitionDetailObj.storageUsed === 0">0 MB</div>
                      <div class="heading-1" v-if="competitionDetailObj.storageUsed > 0">{{competitionDetailObj.storageUsed | getFileSize}}</div>
                      <div class="text-text1 heading-3">Storage Used</div>
                    </div>
                  </div>
                </div>
                <div @click="goToActivity()" class="">
                  <div  class="flex items-center h-28 hover:border-primary pl-6 border-l-4 border-primary3  hover:shadow-md cursor-pointer cust_card">
                    <div class="flex items-center">
                      <div class="heading-3 text-text2"><i class="fa-solid fa-clock-rotate-left"></i></div>
                      <div class="text-text1 heading-3 pl-2">Activity Log</div>
                    </div>
                  </div>
                </div>
                <div @click="addDummyData()" class="" v-if="isShowButton && $route.params.orgId === 64">
                  <div  class="flex items-center h-28 hover:border-secondary pl-6 border-l-4 border-secondary  hover:shadow-md cursor-pointer cust_card">
                    <div class="flex items-center">
                      <div class="text-secondary heading-4 font-semibold pl-2">Add Dummy Visits</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="overflow-y: autos; white-space: nowrap;" class="p-3 cust_card">
          <div class="heading-3 text-text1 ">● Plan Detail:</div>
          <!-- <div class="heading-3 text-text1">● Basic Detail:</div> -->
          <div class="grid grid-cols-12 mt-4">
            <div class="box col-span-12 xl:col-span-12">
              <div class="">
                <div class="p-1">
                  <p class="mb-0" style="display: flex;">
                    <span class="text-text2 heading-5 font-bold"><p class="mb-0" style="width:173px">Plan Name</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 heading-5" v-if="planDetail.currentPlanName !== ''">{{planDetail.currentPlanName}}</span>
                    <span class="text-text1 heading-5" v-else>--</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0" style="display: flex;">
                    <span class="text-text2 heading-5 font-bold"><p class="mb-0" style="width:173px">Subscribed On</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 heading-5 whitespace-pre-line" v-if="planDetail.currentPlanSubscribedOn !== ''">{{planDetail.currentPlanSubscribedOn | dateTimeWithName}}</span>
                    <span class="text-text1 heading-5" v-else>--</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0" style="display: flex;">
                    <span class="text-error heading-5 font-bold"><p class="mb-0" style="width:173px">Expired on</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-error heading-5" v-if="planDetail.currentPlanExpiryDate !== ''">
                      <div class="flex items-center">
                        <span>{{planDetail.currentPlanExpiryDate | dateTimeWithName}} </span>
                        <span class="pl-2" @click="openExpirePopup()" v-if="planDetail.isFreePlan"><i class="fa-solid fa-pen-to-square text-primary cursor-pointer"></i></span>
                      </div>
                      <span class="heading-6 text-gray4 font-semibold">({{getExpiryDifference(planDetail.currentPlanExpiryDate)}})</span>
                    </span>
                    <span class="text-text1 heading-5" v-else>--</span>
                    <span v-if="planExpired && detailextValue === 0" class=" px-4 cursor-pointer text-primary" @click="extedEditPopup = true" >Extend days</span>
                  </p>
                </div>
                <div v-if="planExpired && detailextValue !== 0" class="p-1"> 
                  <p class="mb-0" style="display: flex;">
                    <span class="text-error heading-5 font-bold"><p class="mb-0" style="width:173px">Extend Days</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span v-if="detailextValue !== -1" class="text-error heading-5">{{detailextValue}} {{`${detailextValue === 1 ? 'Day' : 'Days'}`}} </span>
                    <span v-else class="text-error heading-5">Life time (-1)</span>
                    <span class=" px-4 cursor-pointer text-primary" @click="extedEditPopup = true" v-if="planExpired">Update</span>
                  </p>
                </div>
                <div class="p-1">
                  <p class="mb-0" style="display: flex;">
                    <span class="text-text2 heading-5 font-bold"><p class="mb-0" style="width:173px">Plan Subscription</p></span>
                    <span><p class="mb-0 dotwidth">:</p></span>
                    <span class="text-text1 heading-5">{{planDetail.isFreePlan ? 'Free' : 'Paid'}}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="overflow-y: autos; white-space: nowrap;" class="pt-1" id="customers">
          <CustomerList :orgId="$route.params.orgId" />
        </div>
        <div style="overflow-y: autos; white-space: nowrap;" class="pt-1" id="leads">
          <LeadListing :orgId="$route.params.orgId" />
        </div>
        <div style="overflow-y: autos; white-space: nowrap;" class="pt-1" id="jobs">
          <JobList :orgId="$route.params.orgId" />
        </div>
        <div style="overflow-y: autos; white-space: nowrap;" class="pt-1"  id="users">
          <UserList :orgId="$route.params.orgId" />
        </div>
        <div style="overflow-y: autos; white-space: nowrap;" class="pt-1"  id="devices">
          <DeviceList :orgId="$route.params.orgId" />
        </div>
        <div style="overflow-y: autos; white-space: nowrap;" class="pt-1" id="invoices">
          <InvoiceList :orgId="$route.params.orgId" />
        </div>
        <div style="overflow-y: autos; white-space: nowrap;" class="pt-1" id="payments">
          <PaymentList :orgId="$route.params.orgId" />
        </div>
      </div>
    </div>
    <button v-show="scY > 200" @click="toTop" class="bg-primary rounded-full h-10 w-10 fixed bottom-3 right-2"><i class="fas fa-caret-up text-white"></i></button>
    <div v-if="openEditPopup" class="popup_overlay px-4">
      <div class="custom_dialog rounded-lg" style="width: 360px;max-height: 95%;">
        <div class="relative bg-white">
          <div>
            <div class="bg-primary flex justify-between items-center p-3">
              <div class=" font-medium text-white heading-4">
                  Update Expiry Date
              </div>
                <div>
                  <span class=" text-text1 heading-6 font-semibold cursor-pointer bg-gray1 px-2.5 py-1.5 rounded-lg uppercase" @click="openEditPopup = false;Err = ''">
                    Cancel
                  </span>
                  <span class=" text-white heading-6 ml-2 font-semibold cursor-pointer bg-success px-2.5 py-1.5 rounded-lg uppercase" @click="saveUpdate()">
                    Save
                  </span>
                </div>
            </div>
            <div class="mb-4 sm:mb-0">
              <div class="flex justify-center p-4">
                <div style="width: 305px">
                  <DateTimePickerCustom
                      v-model="updateExpiryDate"
                      :title="''"
                      :showOnlyDate="true"
                      :hideGoToday="true"
                      :hideBtn="true"
                      :roundedCard="true"
                      :showStartDate="true">
                  </DateTimePickerCustom>
                </div>
              </div>
            </div>
              <!-- <div class="p-4 justify-center flex">
                <VDatePicker ref="calendar1" v-model='updateExpiryDate' mode="date" />
              </div> -->
          </div>
        </div>
      </div>
    </div>
    <div v-if="extedEditPopup" class="popup_overlay px-4">
      <div class="custom_dialog rounded-lg" style="width: 360px;max-height: 95%;">
        <div class="relative bg-white">
          <div>
            <div class="bg-primary flex justify-between items-center p-3">
              <div class=" font-medium text-white heading-4">
                  Extend days
              </div>
                <div>
                  <span class=" text-text1 heading-6 font-semibold cursor-pointer bg-gray1 px-2.5 py-1.5 rounded-lg uppercase" @click="extedEditPopup = false">
                    Cancel
                  </span>
                  <span class=" text-white heading-6 ml-2 font-semibold cursor-pointer bg-success px-2.5 py-1.5 rounded-lg uppercase" @click="saveExted()">
                    Save
                  </span>
                </div>
            </div>
            <div class="mb-4 sm:mb-0">
              <div class="flex justify-center p-4">
               <TextField class=" w-96"
                        :placholderText="`Enter exted days`"
                        :lableText="'Enter exted days'"
                        :inputId="'extendValue'"
                        :inputext="extendValue"
                        :inputType="'number'"
                        :textRight="false"
                        :autoFocus="true"
                        @inputChangeAction="(data) => extendValue = data" 
                      />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="addDummyPop" class="popup_overlay px-4">
      <div class="custom_dialog rounded-lg" style="width: 560px; max-height: 95%;">
        <div class="relative bg-white">
          <div>
            <div class="bg-primary flex justify-between items-center p-3">
              <div class="font-medium text-white heading-4">
                Add Dummy Visits
              </div>
              <div>
                <span class="text-text1 heading-6 font-semibold cursor-pointer bg-gray1 px-2.5 py-1.5 rounded-lg uppercase" @click="uploadFIle('close')">
                  Cancel
                </span>
                <span class="text-white heading-6 ml-2 font-semibold cursor-pointer bg-success px-2.5 py-1.5 rounded-lg uppercase" @click="uploadFIle('save')">
                  Upload File
                </span>
              </div>
            </div>
            <div class="mb-4 sm:mb-0">
              <div class="p-4">
                <div class="pb-2 text-text2 font-semibold heading-5">Please select your .JSON file below</div>
                <input 
                type="file" 
                accept=".json" 
                @change="handleFileUpload" 
                class="border rounded-lg p-2 w-full" 
                />
                <div class="pb-2 text-error heading-6">{{Err}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getCookies} from '@/utils/cookies'
import {setCookiesWithExpire} from '@/utils/cookies.js'
import DateTimePickerCustom from '@/View/components/dateTimepicker'
import TextField from '@/View/components/textfield.vue'
import ADMINAPI from '@/View/Admin/api/Admin.js'
// import Pagination from '@/View/components/pagination.vue'
import deboucneMixin from '@/mixins/debounce.js'
import UserList from '@/View/Admin/users/listing'
import CustomerList from '@/View/Admin/customer/specCustListing.vue'
import JobList from '@/View/Admin/components/jobList.vue'
import InvoiceList from '@/View/Admin/components/invoiceList.vue'
import PaymentList from '@/View/Admin/components/paymentList.vue'
import LeadListing from '@/View/Admin/lead/listing.vue'
import DeviceList from '@/View/Admin/device/specificListing.vue'
export default {
  components: {
    TextField,
    DateTimePickerCustom,
    UserList,
    JobList,
    PaymentList,
    CustomerList,
    InvoiceList,
    LeadListing,
    DeviceList
  },
  mixins: [deboucneMixin],
  data () {
    return {
      Err: '',
      addDummyPop: false,
      detailextValue: 0,
      extendValue: 0,
      extedEditPopup: false,
      planExpired: false,
      readMoreNote: false,
      datePickerVal: false,
      updateExpiryDate: '',
      openEditPopup: false,
      isLoading: false,
      compDetail: null,
      planDetail: [],
      scY: 0,
      scTimer: 0,
      useDetailId: 0,
      isShowFullDesc: true,
      isShowShortDesc: true,
      mobileView: false,
      competitionDetailObj: [],
      selectedFile: '',
      isShowButton: true,
    }
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted () {
    console.log('------------------------------', getCookies('dummyDataAddedTime'))
    let dateTime = getCookies('dummyDataAddedTime')
    if (dateTime !== null && dateTime !== undefined) {
      this.isShowButton = false
    } else {
      this.isShowButton = true
    }
    this.updateExpiryDate = new Date()
    window.scrollTo({top: 0})
    window.addEventListener('scroll', this.handleScroll);
    this.resizeWindowHandler();
    setTimeout(() => {
      document.title = 'Organization Detail'
    }, 1000)
    this.getCompetDetail()
  },
  watch: {
    competitionDetailObj: {
      handler () {
        console.log('Watch On:-competitionDetailObj', this.competitionDetailObj)
      },
      deep: true
    }
  },
  methods: {
    uploadFIle (from) {
      if (from === 'close') {
        this.addDummyPop = false
        this.selectedFile = ''
        this.Err = ''
      } else {
        if (this.selectedFile === '') {
          this.Err = 'Please uplaod file'
        } else {
          console.log('this.selectedFile', this.selectedFile)
          const now = new Date();
          const year = now.getFullYear();
          const month = now.getMonth() + 1; // Months are zero-based (0 = January, 1 = February, etc.)
          const date = now.getDate();
          this.$store.dispatch('SetLoader', {status: true, message: 'Creating...'})
          ADMINAPI.CreateTestJobs(
            parseInt(this.$route.params.orgId),
            '',
            year,
            month,
            date,
            this.selectedFile,
            response => {
              setCookiesWithExpire('dummyDataAddedTime', response.Data, 1)
              this.addDummyPop = false
              this.getCompetDetail()
              this.selectedFile = ''
              this.$store.dispatch('SetLoader', {status: false, message: ''})
              this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            },
            error => {
              this.isLoading = false
              this.$store.dispatch('SetLoader', {status: false, message: ''})
              this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
            }
          )
        }
      }
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file && file.type === 'application/json') {
        // Process the JSON file
        console.log('Selected file:', file);
        this.selectedFile = file
        this.Err = ''
        // You can use FileReader or any other method to read the file content
      } else {
        alert('Please select a valid JSON file.');
      }
    },
    openExpirePopup () {
      this.updateExpiryDate = this.planDetail.currentPlanExpiryDate
      this.openEditPopup = true
    },
    saveUpdate () {
      console.log('this.updateExpiryDate', this.updateExpiryDate)
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.UpdateExpiryDate(
        this.planDetail.subscriptionId,
        this.updateExpiryDate,
        '',
        response => {
          this.openEditPopup = false
          this.Err = ''
          this.getCompetDetail()
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
        },
        error => {
          this.isLoading = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    goToAttchment () {
      this.$router.push({name: 'attachmentList', query: {fromorgid: parseInt(this.$route.params.orgId)}})
    },
    addDummyData () {
      this.addDummyPop = true
    },
    goToActivity () {
      let routeData1 = this.$router.resolve({name: 'ActivityLog', query: {fromorgid: parseInt(this.$route.params.orgId)}})
      window.open(routeData1.href, '_blank')
    },
    saveExted () {
      console.log('this.updateExpiryDate', this.updateExpiryDate)
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.ExtendExpiryDays(
        this.compDetail.organizationId,
        this.extendValue,
        response => {
          console.log('check response', response);
          this.extedEditPopup = false
          this.getCompetDetail()
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
        },
        error => {
          console.log('check errr', error);
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    smoothScroll (target) {
      window.scrollTo({
        top: document.getElementById(target).offsetTop - 60,
        left: 0,
        behavior: "smooth",
      });
    },
    resizeWindowHandler() {
      if (window.innerWidth < 684) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
    },
    handleScroll () {
      if (this.scTimer) return
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY
        clearTimeout(this.scTimer)
        this.scTimer = 0
      }, 100)
    },
    toTop () {
      window.scrollTo({top: 0, behavior: "smooth"})
    },
    redirectToList() {
      // this.$router.push({name: 'Competition'})
      this.$router.go(-1)
    },
    getCompetDetail () {
      this.isLoading = true
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.getOrganizationDetail(
        parseInt(this.$route.params.orgId),
        response => {
          this.competitionDetailObj = response.Data === null ? [] : response.Data
          this.compDetail = this.competitionDetailObj.organizationDetail === null ? null : this.competitionDetailObj.organizationDetail
          this.planDetail = this.competitionDetailObj.subscriptionDetail === null ? [] : this.competitionDetailObj.subscriptionDetail
          this.isLoading = false
          this.extendValue = this.planDetail.extendValue
          this.detailextValue = this.planDetail.extendValue

        if (this.planDetail.currentPlanExpiryDate) {
          let expiryDate =  new Date(this.planDetail.currentPlanExpiryDate)
          const currentDate = new Date();

        console.log("expiryDate", expiryDate);
        console.log("currentDate", currentDate);
        console.log("expiryDate < currentDate", expiryDate < currentDate);

          if (expiryDate < currentDate) {
            this.planExpired = true
          } else {
            this.planExpired = false
          }
          console.log('------------------------------', getCookies('dummyDataAddedTime'))
            let dateTime = getCookies('dummyDataAddedTime')
            if (dateTime !== null && dateTime !== undefined) {
              this.isShowButton = false
            } else {
              this.isShowButton = true
            }
        }
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.isLoading = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    getExpiryDifference(expiryDate) {
      const now = new Date();
      const endDate = new Date(expiryDate);
      const diff = endDate - now;
      if (diff < 0) {
        return "Expired";
      }
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const years = Math.floor(days / 365);
      const months = Math.floor((days % 365) / 30);
      const remainingDays = days - (years * 365) - (months * 30);
      let result = '';
      if (years > 0) {
        result += years + " Year" + (years > 1 ? "s " : " ");
      }
      if (months > 0) {
        result += months + " Month" + (months > 1 ? "s " : " ");
      }
      if (remainingDays > 0) {
        result += remainingDays + " Day" + (remainingDays > 1 ? "s" : "");
      }
      return result.trim() + " Remaining" || "0 Days";
    }
  }
}
</script>
<style scoped>

.lableWidth {
  width: 105px;
}
.dotwidth {
  width: 15px;
}
.list_top_card_btn {
  width: 83px !important;
  margin-right: 15px !important;
}
.ImageTag {
  max-height: 300px;
  width: auto;
}
</style>
