import { render, staticRenderFns } from "./specificListing.vue?vue&type=template&id=27126c15&scoped=true&"
import script from "./specificListing.vue?vue&type=script&lang=js&"
export * from "./specificListing.vue?vue&type=script&lang=js&"
import style0 from "./specificListing.vue?vue&type=style&index=0&id=27126c15&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27126c15",
  null
  
)

export default component.exports