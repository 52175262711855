<template>
  <div class="">
    <!-- <div>
      <div>
      </div>
        <div class="overFlowPara">
          {{title}}
        </div>
    </div> -->
    <!-- <v-divider></v-divider> -->
     <div class="main_div rounded-xl overflow-hidden shadow-lg">
      <p v-if="title !== ''" class="p-2 text-xl text-white bg-primary">{{title}}</p>
      <div class="pa-1 p-2">
       <div :class="!hideTimePicker ? 'noDate_Section' : 'nodate_Statr'" v-if="clearDateTime">
         <div @click="setDateTime()" class="no_date_box cursor-pointer text-base flex justify-center">
           <a text>Click Set Date Time</a>
         </div>
       </div>
       <div v-if="!clearDateTime">
        <div v-if="fromProject === false && !hideTopHeadingDate">
          <div xl12 md12 sm12 xs12 lg12 class="py-1" v-if="dateValue !== '' || timeValue !== ''">
            <div class="text-center pa-2">
              <p class="date_time_picker__header">{{dateValue | DatePickerDateHeader}} <span v-if="timeValue !== '' && !hideTimePicker">at</span> <span v-if="!hideTimePicker">{{timeValue | DatePickerTimeHeader}}</span></p>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap" style="padding: inherit;">
          <div :style="isMobileView ?'max-width: 100%; display: contents;': ''" class="">
            <cdatepicker
              :roundedCard="roundedCard"
              :firstDayOfTheWeek="$store.getters.firstDayOfTheWeek"
              :backgroundColor="'#f0f0f0'"
              v-model="dateValue"
              :changeBackgroundOfDayInWeek="'sunday'"
              :min="minDate"
            :max="maxDate"/>
            <!-- <v-divider style="border-color: #f7f3f3;"></v-divider> -->
            <div v-if="showDate" class="dbtns timepicker_wraper" xl12 md12 lg12 sm12 xs12>
              <div style="padding: 2px;" class="border-b border-t text-primary border-gray2 ">
              <a text class="mr-1 cursor-pointer pl-3" v-if="showDate" @click="todayDate()">Today</a>
              <a text class="mr-1 cursor-pointer " v-if="showDate" @click="todayDate(1)">+1</a>
              <a text class="mr-1 cursor-pointer " v-if="showDate" @click="todayDate(2)">+2</a>
              <a text class="mr-1 cursor-pointer " v-if="showDate" @click="todayDate(3)">+3</a>
              <a text class="mr-1 cursor-pointer " v-if="showDate" @click="todayDate(5)">+5</a>
              <a text class="mr-1 cursor-pointer " v-if="showDate" @click="todayDate(7)">+7</a>
              <a text class="mr-1 cursor-pointer " v-if="showDate" @click="todayDate(15)">+15</a>
              <a text class="mr-3 cursor-pointer float-right mt-2 " v-if="showStartDate && !hideClear"  @click="noDate()">Clear</a>
              <a text class="mr-3 cursor-pointer float-right mt-2 " v-if="showEndDate && !hideClear"  @click="noDate()">Clear</a>
              </div>
              <div v-if="!hideTimePicker" class="text-right set_time" >
                <!-- <v-divider style="border-color: rgb(0 0 0);"></v-divider> -->
                <p v-if="!setTimeSection" class="cursor-pointer" text @click="setTime()"><span>Set Time</span></p>
              <div v-if="setTimeSection">
                <customeTimePicker v-if="fromProject === true" v-model="timeValue"/>
              </div>
              </div>
            </div>
          </div>
          <div style="max-width: 100%" v-if="!hideTimePicker">
            <ctimepicker v-if="fromProject === false" v-model="timeValue"/>
          </div>
            <!-- <div v-if="setTimeSection">
              <customeTimePicker v-if="fromProject === true" v-model="timeValue"/>
            </div> -->
        </div>
        </div>
      <div wrap class="align-center mt-1" >
        <div class="text-right" v-if="dateValue !== today && !showDate && !hideGoToday">
            <a class="mr-2" @click="todayDate()">Go to Today</a>
          </div>
        <div v-if="showButton"  class="flex gap-2 justify-end mr-2">
          <!-- <button  class="mr-1 px-4 py-2 discard_button rounded-3xl" @click="closeDateTimepickerDialog(false)">
            Discard
          </button>
          <button @click="saveDateTime" class="button-color text-white px-4 py-2 rounded-3xl">
            Save
          </button> -->
          <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="closeDateTimepickerDialog(false)"/>
          <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="saveDateTime"/>
        </div>
      </div>
     </div>
     </div>
  </div>
</template>
<script>
import cdatepicker from '@/View/components/datepicker/datepicker.vue'
import ctimepicker from '@/View/components/timepicker/timepicker.vue'
import customeTimePicker from '@/View/components/timepicker/customeTimePicker.vue'
import Button from '@/View/components/globalButton.vue'
import moment from 'moment'
export default {
  components: {
    ctimepicker,
    cdatepicker,
    customeTimePicker,
    Button
  },
  props: [ "goToday", 'hideTopHeadingDate', 'roundedCard', 'hideGoToday','minDate', 'maxDate', 'value', 'title', 'showOnlyDate', 'showDate', 'showEndDate', 'showStartDate', 'hideBtn', 'projectSection', 'hideClear'],
  model: {
    prop: 'value',
    event: 'input'
  },
  data () {
    return {
      clearDateTime: false,
      setTimeSection: false,
      fromProject: false,
      showButton: true,
      isMobileView: false,
      dateValue: '',
      timeValue: '',
      today: moment(new Date()).format('YYYY-MM-DD'),
      hideTimePicker: false
    }
  },
  beforeMount () {
    if (this.projectSection === true) {
      this.fromProject = true
    }
    if (this.hideBtn) {
      this.showButton = false
    }
    if (this.showOnlyDate !== undefined && this.showOnlyDate === true) this.hideTimePicker = true
    if (this.value !== '' && this.value !== null && this.value !== 'Invalid date') {
      let date = new Date(this.value)
      this.dateValue = moment(date).format('YYYY-MM-DD')
      // this.dateValue = date.toISOString().substr(0, 10)
      if (this.value.length >= 18) this.timeValue = date.toTimeString().substr(0, 8)
    }
    if (this.hideTimePicker === false) {
      if (this.timeValue !== '00:00:00' & this.timeValue !== undefined & this.timeValue !== '') {
        this.setTimeSection = true
      }
    }
    if (this.showOnlyDate !== true && (this.timeValue === '00:00:00' || this.value === '')) {
      if (this.showOnlyDate !== undefined) {
        this.timeValue = '19:00:00'
      }
    }
    // console.log("hideTimePicker", this.hideTimePicker);
    // console.log("setTimeSection", this.setTimeSection);
  },
  watch: {
    goToday: {
      handler () {
        console.log('call today paente');
        if (this.goToday) {
          this.dateValue = ''
          console.log('call today paente 2222');
          this.todayDate()
        }
      },
      deep: true
    }
  },
  mounted () {
    // this.$root.$on('callTodayFromParentCompHandler', (response) => {
    //     if (response) {
    //       console.log('call today paente');
    //       this.todayDate()
    //     }
    //   })
    if (this.hideTimePicker === true) {
      this.$root.$on('dateSelectionEvent', (data) => {
        if (data) {
          if (this.showButton === false) {
            this.saveDateTime()
            this.updateDeadline(data)
          }
        }
      })
    }
  },
  methods: {
    setTime () {
      this.setTimeSection = true
    },
    checkMobileView () {
      if (window.innerWidth < 960) {
        this.isMobileView = true
      } else {
        this.isMobileView = false
      }
    },
    updateDeadline (data) {
      this.$root.$emit('updateDeadlineDate', data)
    },
    closeDateTimepickerDialog (date) {
      this.$emit('closeDateTimepickerDialog', date)
      this.$root.$emit('closeDatepciker', false)
      this.$root.$emit('closeDialog', false)
    },
    saveDateTime () {
      if (this.dateValue === '') {
        this.timeValue = ''
      }
      this.$emit('input', `${this.dateValue} ${this.timeValue}`.trim())
      this.closeDateTimepickerDialog()
    },
    todayDate (num) {
      console.log('num', num)
      let date = new Date()
      if (num !== undefined) {
        var nextDay = new Date()
        date = new Date(nextDay.setDate(nextDay.getDate() + num))
      }
      this.dateValue = moment(date).format('YYYY-MM-DD')
      console.log('dateValue --->', this.dateValue);
      // this.dateValue = new Date(new Date().setDate(new Date().getDate())).toISOString().substr(0, 10)
      // if (num === undefined) {
      //   this.saveDateTime()
      //   this.updateDeadline(this.dateValue)
      // }
      // console.log(this.dateValue)
    },
    noDate () {
      this.clearDateTime = true
      this.setTimeSection = false
      this.dateValue = ''
      this.timeValue = ''
      // this.saveDateTime()
      // console.log(this.dateValue)
    },
    setDateTime () {
      this.clearDateTime = false
    }
  },
  destroyed () {
    this.$root.$off('dateSelectionEvent')
    this.$root.$off('callTodayFromParentCompHandler')
  }
}
</script>
<style scoped>
.top__divider {
  border-top: 0.5px solid lightgray;
}
.bottom__divider {
  border-bottom: 0.5px solid black;
}
.noDate_Section {
  height: 404px !important;
  width: 290px !important;
}
.nodate_Statr {
  height: 334px !important;
  width: 290px !important;
}
.no_date_box {
  height: inherit !important;
  width: 100%;
  align-items: center !important;
}
.no_date_box a{
  width: 100%;
  text-align: center;
  color:#1976D2;
}
.text-color {
  color:#1976D2;
}
.timepicker_wraper {
  font-family: 'Roboto-Regular' !important;
  max-width: 290px !important;
  width: 290px !important;
  overflow: hidden;
  height: fit-content !important;
  background: white;
  box-shadow: 0 2px 4px 0 rgba(92, 91, 91, 0.2);
}
.dbtns a {
  font-size: 14px;
  line-height: 10px;
  align-items: center;
}
.dbtns .set_time {
  height: 75px !important;
  text-align: center !important;
}
.dbtns .set_time p {
  color: black;
  height: 75px !important;
  margin: auto;
  width: 100%;
  padding: 21px;
}
.date_time_picker__header {
  font: large;
  font-weight: bold;
}
/* .overFlowPara {
    white-space: nowrap !important;
    overflow: hidden !important;
    overflow-x: hidden;
    text-overflow: ellipsis !important;
    -webkit-line-clamp: 1 !important;
} */
.button-color {
    background-color: #1976D2;
}
.main_div {
    background-color: #f5f5f5;
}
.discard_button:hover {
  background-color: #EEEEEE;
}
</style>