<template>
  <div class="h-full cust_card p-2 mt-2" style="overflow: visible !important;">
    <div class="heading-3 text-text1">● Invoice List:</div>
    <div class="bottom_space rounded py-2 h-full pt-4 bg-white">
      <div class="flex items-center flex-wrap pb-2">
        <div class="">
          <div class="">
          <button v-for="(status, index) in statusList" :key="index"
            :class="statusList[0].isActive ? 'bg-info text-text1 border-info' : status.isActive ? 'bg-info border-info text-text1' : 'bg-white border-gray2 text-text1'" class="mr-2 text-sm border hover:border-info py-2.5 px-2  text-text1 rounded-md list_btn" @click="toggleActiveAndInactive(index)">{{status.statusName}}</button>
          </div>
        </div>
      </div>
      <div class="bottom_space flex justify-between">
          <div class="mb-2 flex">
            <div class="max-w-max mr-4">
              <div class="select_button text-sm text-text2">
              <SimpleDropdown :dropdownList="items" @callApiAsperSelectValue1="getLeaveDataAsPerPagination1"/>
              </div>
            </div>
            <div style="display:flex" class="search_customer_box text-text2 bg-white border border-gray-500 rounded-md px-2 py-1 items-center h-11 mr-4">
              <span class="mr-2 cursor-pointer"><img height="20px" width="20px" src="@/assets/images/svg/gray-search.svg" alt=""></span>
              <input class="focus:outline-none w-72 text-text2" autocomplete="off" type="text" name="" placeholder="Search" id="custSearchInput"
                v-model="searchForCustomer"
                @input="getContactListDeboucne"
              >
              <span v-if="searchForCustomer.length > 0" class="cursor-pointer"><img @click="clearSearch()" height="12px" width="12px" src="@/assets/images/svg/clear-input.svg" alt="clearInput"></span>
            </div>
          </div>
        </div>
      <div>
      <div class="w-full">
        <div class="progress-bar" v-if="searchLoader">
          <div class="progress-bar-value"></div>
        </div>
      </div>
      <div style="overflow-y: auto; white-space: nowrap;" class="table_containder ">
        <div class="text-text2  min-w-min heaer_row border border-gray1 font-bold flex heading-5 p-1 items-center">
          <div class="cell2_col flex items-center p-2">
            <span class="cursor-pointer" :class="titleObj[0].isSel ? 'text-primary' : ''" @click="sortByColumn(0)">{{titleObj[0].title}} </span>
            <img v-if="titleObj[0].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img v-if="titleObj[0].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="name_col  flex items-center p-2">
            <span class="cursor-pointer" :class="titleObj[1].isSel ? 'text-primary' : ''" @click="sortByColumn(1)">{{titleObj[1].title}}</span>
            <img class="pl-1" v-if="titleObj[1].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[1].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="cell_col  flex items-center p-2 justify-end">
            <span class="cursor-pointer" :class="titleObj[2].isSel ? 'text-primary' : ''" @click="sortByColumn(2)">{{titleObj[2].title}}</span>
            <img class="pl-1" v-if="titleObj[2].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[2].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="cell_col  flex items-center p-2 justify-end">
            <span class="cursor-pointer" :class="titleObj[3].isSel ? 'text-primary' : ''" @click="sortByColumn(3)">{{titleObj[3].title}}</span>
            <img class="pl-1" v-if="titleObj[3].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[3].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="name_col  flex items-center p-2 justify-center">
            <span class="cursor-pointer" :class="titleObj[4].isSel ? 'text-primary' : ''" @click="sortByColumn(4)">{{titleObj[4].title}}</span>
            <img class="pl-1" v-if="titleObj[4].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[4].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="name_col  flex items-center p-2 ">
            <span class="cursor-pointer" :class="titleObj[5].isSel ? 'text-primary' : ''" @click="sortByColumn(5)">{{titleObj[5].title}}</span>
            <img class="pl-1" v-if="titleObj[5].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[5].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
          <div class="name_col  flex items-center p-2 ">
            <span class="cursor-pointer" :class="titleObj[6].isSel ? 'text-primary' : ''" @click="sortByColumn(6)">{{titleObj[6].title}}</span>
            <img class="pl-1" v-if="titleObj[6].sortVal === 'DESC'" src="@/assets/images/svg/DESC.svg" /> 
            <img class="pl-1" v-if="titleObj[6].sortVal === 'ASC'" src="@/assets/images/svg/ASC.svg" /> 
          </div>
        </div>
        <div v-for="(data, index) in organizationList" :key="index" class="min-w-min group flex border-r border-l border-b border-gray1  bg-white  text-sm p-1 items-center cursor-pointer  hover:bg-gray-50" @click="openDetail(data)">
          <div class="cell2_col p-2 ">
            <span class="heading-4 text-text2 " v-if="data.invoiceNumber !== ''">{{data.invoiceNumber}}</span>
            <span class="" v-else>--</span>
          </div>
          <div class="name_col p-2" >
            <span class="heading-4 text-text2" v-if="data.companyName !== ''">{{data.companyName}}</span>
            <span class="" v-else>--</span>
          </div>
          <div class="cell_col p-2 text-right">
            <span class="heading-4 text-text2" v-if="data.recordedPayment !== ''">{{data.recordedPayment | amountFormaterForTax}}</span>
            <span class="" v-else>--</span>
          </div>
          <div class="cell_col p-2 text-right">
            <span class="heading-4 text-text2" v-if="data.grandTotal !== ''">{{data.grandTotal | amountFormaterForTax}}</span>
            <span class="" v-else>--</span>
          </div>
          <div class="name_col p-2 text-center">
            <span class="heading-4" :style="'color:'+data.invoiceStatusColor" v-if="data.invoiceStatusName !== ''">{{data.invoiceStatusName }}</span>
            <span class="" v-else>--</span>
          </div>
          <div class="name_col p-2">
            <span class="heading-4 text-text2" v-if="data.invoiceDate !== ''">{{data.invoiceDate | dateTimeWithName}}</span>
            <span class="" v-else>--</span>
          </div>
          <div class="name_col p-2">
            <span class="heading-4 text-text2" v-if="data.dueDate !== ''">{{data.dueDate | dateTimeWithName}}</span>
            <span class="" v-else>--</span>
          </div>
          
        </div>
      </div>
      <div class="layout my-2" v-if="organizationList.length === 0">
        <div class="flex p-3 text-sm text-primary bg-blue-100 rounded-lg  items-center" role="alert">
          <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
          <span class="sr-only">Info</span>
          <div>
            <p class=" font-medium heading-4">No record found.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-2">
      <Pagination
        :totalDataCount="totalEmpCount"
        :resetPagination="resetPaginationValues"
        @callApiAsPerPagination="getCustomerDataFromPagination"/>
    </div>
  </div>
</div>
</template>
<script>
import ADMINAPI from '@/View/Admin/api/Admin.js'
import SimpleDropdown from '@/View/components/simpleDropdown.vue'
// import Pagination from '@/View/components/pagination.vue'
import Pagination from '@/View/components/pagination.vue'
import deboucneMixin from '@/mixins/debounce.js'
export default {
  components: {
    Pagination,
    SimpleDropdown,
  },
  mixins: [deboucneMixin],
  props: ['orgId'],
  data () {
    return {
      showInActive: true,
      statusList: [],
      mobileView: false,
      searchLoader: false,
      resetPaginationValues: false,
      searchForCustomer: '',
      titleObj: [
        {title: 'Invoice #', icon: '', sortName: 'invoiceNumber', sortVal: '', isSel: false},
        {title: 'Company Name', icon: '', sortName: 'companyName', sortVal: '', isSel: false},
        {title: 'Recorded Payment', icon: '', sortName: 'recordedPayment', sortVal: '', isSel: false},
        {title: 'Grand Total ', icon: '', sortName: 'grandTotal', sortVal: '', isSel: false},
        {title: 'Status', icon: '', sortName: 'invoiceStatusName', sortVal: '', isSel: false},
        {title: 'Invoice Date', icon: '', sortName: 'invoiceDate', sortVal: '', isSel: false},
        {title: 'Due Date', icon: 'fas fa-sort-amount-down', sortName: 'dueDate', sortVal: 'DESC', isSel: true},
    ],
      filterObject: {
        start: 0,
        length: 20,
        searchVal: '',
        sortBy: 'dueDate',
        sortOrder: 'DESC',
        organizationId: 0,
        statusId: '',
        from: '',
        to: '',
      },
      totalEmpCount: 0,
      paginationLength: 1,
      filterCol: 'text-gray-400',
      page: 1,
      items: [20, 25, 30, 50, 100],
      limitResData: 20,
      paginationData: 20,
      organizationList: [],
    }
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted () {
    this.resizeWindowHandler()
    this.getStatus()
    document.title = 'Invoice List'
    this.getContactListDeboucne = this.debounce(function () {
      if (this.searchForCustomer.length > 2) {
        this.resetPaginationValues = true
        this.searchLoader = true
      }
      console.log('debounde')
    }, 500)
    this.getEmployeeList()
  },
  watch: {
    searchForCustomer: {
      handler () {
        if (this.searchForCustomer === null) {
          this.searchForCustomer = ''
        }
        if (this.searchForCustomer === '') {
          this.resetPaginationValues = true
          this.getEmployeeList()
        }
      }
    },
    titleObj: {
      handler () {
        console.log('titleObj 123', this.titleObj)
      }
    },
    paginationData: {
      handler () {
        this.filterObject.length = this.paginationData
        this.showlistLoader = true
        this.getEmployeeList(this.showlistLoader)
      },
      deep: true
    }
  },
  methods: {
    openDetail (data) {
      console.log('data', data)
      this.$router.push({name: 'InvoiceDetail', params: {invoiceId: data.invoiceId}})
    },
    addNewComp () {
      this.$router.push({name: 'AddNewCompetition'})
    },
    resizeWindowHandler() {
      if (window.innerWidth < 684) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
    },
    clearSearch () {
      this.searchForCustomer = ''
      this.searchLoader = false
      this.getEmployeeList()
    },
    getCustomerDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      let showlistLoader = false
      if (data.offset !== 0) {
        showlistLoader = true
      }
      this.getEmployeeList(showlistLoader)
    },
    sortByColumn (index) {
      for (let i = 0; i < this.titleObj.length; i++) {
        if (i === index) {
          console.log('this.titleObj[i].icon', this.titleObj[i].icon)
          console.log('this.titleObj[i].sortVal', this.titleObj[i].sortVal)
          console.log('this.filterObject.sortOrder', this.filterObject.sortOrder)
          if (this.titleObj[i].icon === '') {
            this.titleObj[i].icon = 'fas fa-sort-amount-down'
            this.titleObj[i].sortVal = 'DESC'
            this.filterObject.sortOrder = 'DESC'
            this.titleObj[i].isSel = true
          } else if (this.titleObj[i].icon === 'fas fa-sort-amount-down') {
            this.titleObj[i].icon = 'fas fa-sort-amount-up'
            this.titleObj[i].sortVal = 'ASC'
            this.filterObject.sortOrder = 'ASC'
            this.titleObj[i].isSel = true
          } else {
            this.titleObj[i].icon = 'fas fa-sort-amount-down'
            this.filterObject.sortOrder = 'DESC'
            this.titleObj[i].sortVal = 'DESC'
            this.titleObj[i].isSel = true
          }
      } else {
          this.titleObj[i].icon = ''
          this.titleObj[i].sortVal = ''
          this.titleObj[i].isSel = false
        }
      }
      this.filterObject.sortBy = this.titleObj[index].sortName
      this.getEmployeeList(true)
    },
    toTop () {
      this.$vuetify.goTo(0)
    },
    routingToDetail (data) {
      this.$router.push({name: 'OrganizationDetail', params: {orgId: data.organizationId}})
    },
    toggleActiveAndInactive (index) {
      let filter = []
      if (this.statusList[index].statusName.toLowerCase() === 'all') {
        this.statusList[0].isActive = true
        this.filterObject.statusId = []
      } else {
        this.statusList[index].isActive = !this.statusList[index].isActive
        this.filterObject.statusId = []
        this.statusList.forEach((a, id) => {
          if (this.statusList[0].isActive) {
            if (id !== index && id !== 0) {
              a.isActive = false
            } else {
              if (id !== 0) {
                a.isActive = true
                this.filterObject.statusId.push(a.invoiceStatusId)
              }
            }
          } else {
            if (a.isActive) {
              filter.push(a)
              this.filterObject.statusId.push(a.invoiceStatusId)
            }
          }
        })
        this.statusList[0].isActive = false
      }
      if (filter.length === this.statusList.length - 1) {
        this.statusList[0].isActive = true
        this.filterObject.statusId = []
      }
      let arry = this.statusList.filter(a => {
        return a.isActive === false
      })
      if (arry.length === this.statusList.length) {
        this.statusList[0].isActive = true
        this.filterObject.statusId = []
      }
      this.getEmployeeList()
    },
    getLeaveDataAsPerPagination1 (data) {
      this.filterObject.length = data
      this.getEmployeeList()
    },
    getEmployeeList () {
      console.log('this.routing', this.orgId)
      if (this.orgId === undefined) {
        this.filterObject.organizationId = 0
      } else {
        this.filterObject.organizationId = this.orgId
      }
      if (this.searchForCustomer === '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      ADMINAPI.getInvoiceList(
        this.filterObject.start,
        this.filterObject.length,
        this.searchForCustomer,
        this.filterObject.statusId,
        this.filterObject.sortBy,
        this.filterObject.sortOrder,
        this.filterObject.from,
        this.filterObject.to,
        this.filterObject.organizationId,
        0,
        response => {
          console.log("response", response.Data)
          this.resetPaginationValues = false
          this.organizationList = response.Data.tableRow === null ? [] : response.Data.tableRow
          console.log('this.organizationList', this.organizationList)
          this.totalEmpCount = response.Data.count
          console.log('count', this.totalEmpCount)
          this.searchLoader = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    getStatus () {
      ADMINAPI.getInvoiceStatus(
        response => {
          console.log("response", response.Data)
          let statusList = response.Data === null ? [] : response.Data
          for (let index = 0; index < statusList.length; index++) {
            statusList[index].isActive = false
          }
          this.statusList = statusList
          this.statusList.unshift({
            statusName: 'All',
            isActive: true
          })
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    }
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
.table_containder {
  padding: 1px;
}
.name_col {
  min-width: 270px !important;
  width: 270px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.cell2_col {
  min-width: 120px !important;
  width: 120px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.cell_col {
  min-width: 200px !important;
  width: 200px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.price_col {
  min-width: 200px !important;
  width: 200px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.list_btn {
  min-width: 77px !important;
}
.select_btn {
  min-width: 77px !important;
  max-width: 77px;
}
.select_btn:focus-visible {
  outline: none;
}
.search_customer_box {
  min-width: 335px !important;
  max-width: 335px !important;
}
.list_top_card_btn {
  width: 183px !important;
  margin-right: 15px !important;
}
.row_user_svg {
  height: 36px;
  width: 36px;
}
.navbar-open {
  transform: translateX(0%);
}
.navbar-close {
  transform: translateX(-100%);
}
.demo-container {
  width: 300px;
  margin: auto;
}

.progress-bar {
  height: 2px;
  background-color: rgb(18,149,186);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: rgb(121, 200, 221);
  animation: indeterminateAnimation 2s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
::placeholder {
  font-size: 16px;
  color: #494949;
}
.lableWidth {
  width: 105px;
}
.dotwidth {
  width: 15px;
}
.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #307dea;
  width: 27px;
  height: 27px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
